import React, { PureComponent, Fragment } from 'react';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch, faChevronRight, faChevronLeft, faDownload, faFileDownload, faEye, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n.js';
import Filter from '../Filter/Filter.js';
import DocumentTable from '../DocumentTable/DocumentTable.js';
import './App.css';

export const DOCUMENT_TYPE = 'documentType';
export const ISSUER = 'issuer';
export const ISO_CODE = 'isoCode';
export const WT_REGION = 'wtRegion';
export const IDENTIFIER = 'identifier';
export const PUBLISHED = 'Published';

// Initialize the Font Awesome library
library.add(faCircleNotch, faChevronRight, faChevronLeft, faDownload, faFileDownload, faEye, faCopy);

class App extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      documents: null,
      baseUrl: `${props.params.dataspanApiUrl}/pdr/documents/`,
      documentType: null,
      issuer: null,
      wtRegion: null,
      isoCode: null,
      identifier: null,
      waitingForZip: true,
      loading: true,
    };
    this.updateFilter = this.updateFilter.bind(this);
    this.handleDownloadAll = this.handleDownloadAll.bind(this);
  }

  componentDidMount() {
    this.getDocumentDetails(this.props.params);
  }

  // Update the relevant filter based on a callback from the
  // filter component
  updateFilter(filterName, selectedOption) {

    this.setState({
      [filterName]: selectedOption,
    });
  }

  // Filter a set of documents by a given property
  filterProperty(property, documents, selectedOption) {

    if (!selectedOption) {
      return documents;
    }
    return documents.filter(doc => doc[property] === selectedOption);
  }

  filterDocuments() {
    const {
      documents,
      documentType,
      issuer,
      wtRegion,
      isoCode,
      identifier,
    } = this.state;

    if (!documents) {
      return null;
    }

    let docsToShow = this.filterProperty(DOCUMENT_TYPE, documents, documentType);
    docsToShow = this.filterProperty(ISSUER, docsToShow, issuer);
    docsToShow = this.filterProperty(WT_REGION, docsToShow, wtRegion);
    docsToShow = this.filterProperty(ISO_CODE, docsToShow, isoCode);
    docsToShow = this.filterProperty(IDENTIFIER, docsToShow, identifier);

    return docsToShow;

  }

  handleDownloadAll(outputType) {

    const {
      params,
    } = this.props;

    const {
      documentType,
      issuer,
      wtRegion,
      isoCode,
      identifier,
      baseUrl,
    } = this.state;

    const documentTypes = documentType ? documentType : params.documentsToLoad.map( doc => doc.documentType).join(',');

    let url = this.addUrlParam(`${baseUrl}?`, DOCUMENT_TYPE, documentTypes);
    url = this.addUrlParam(url, ISSUER, issuer);
    url = this.addUrlParam(url, WT_REGION, wtRegion);
    url = this.addUrlParam(url, ISO_CODE, isoCode);
    url = this.addUrlParam(url, IDENTIFIER, identifier);

    // Add on the output type which could be zip or csv
    url = `${url}outputType=${outputType}`;

    window.location.assign(url);
    
  }

  // Add on each url param if the value exists
  addUrlParam(url, param, value) {
    if (value){
      return (`${url}${param}=${value}&`);
    }
    return url;
  }

  render() {

    const {
      params,
    } = this.props;

    const {
      documents,
      initialDocumentType,
      baseUrl,
      loading,
    } = this.state;

    const docsToShow = this.filterDocuments();
    const disableCsvButton = docsToShow && docsToShow.length > 0 ? false : true;
    const disableZipButton = docsToShow && docsToShow.length <= 100 ? false : true;

    return (
      <div className="app">

        { loading ? 
         <div className='waiting'>
          <FontAwesomeIcon icon="circle-notch" size="3x" className="waiting-icon" spin />
          </div>
          : null}

        {documents && docsToShow ?
          <Fragment>

            <div className='filter-row'>
              <Filter
                documents={documents}
                docsToShow={docsToShow}
                updateFilter={this.updateFilter}
                initialDocumentType={initialDocumentType}
                identifierName={params.identifierName}
              />

              <div className='download-buttons'>
                <div className='download-all'>
                  <button
                    onClick={this.handleDownloadAll.bind(this, 'zip')}
                    disabled={disableZipButton}
                  >
                    {i18n.t('download-all')}
                  </button>
                </div>

                <div className='download-all'>
                  <button
                    onClick={this.handleDownloadAll.bind(this, 'csv')}
                    disabled={disableCsvButton}
                  >
                    {i18n.t('download-csv')}
                  </button>
                </div>
              </div>

            </div>

            <DocumentTable
              documents={docsToShow}
              baseUrl={baseUrl}
              identifierName={params.identifierName}
            />
          </Fragment>
          : null }
      </div>
    );
  }

  async callApi(docToLoad) {

    const {
      baseUrl,
    } = this.state;

    const params = {
      documentType: docToLoad.documentType,
      issuer: docToLoad.issuer ? docToLoad.issuer : null, 
      wtRegion: docToLoad.wtRegion ? docToLoad.wtRegion : null, 
      isoCode: docToLoad.isoCode ? docToLoad.isoCode: null, 
      identifier: docToLoad.identifier? docToLoad.identifier: null, 
      fileBusinessDate: docToLoad.fileBusinessDate? docToLoad.fileBusinessDate: null, 
      outputType: 'json',
    };

    const request = {
      method: 'GET',
      url: baseUrl,
      params: params,
    };

    try {

      // Get the fund data from dataspan
      const response = await axios(request);

      if (response.status === 200) {
        return response.data;
      }

    } catch (e) {
      console.log(e);
    }

    return null;

  }

  // Get the details for all the documents 
  async getDocumentDetails() {

    const {
      params,
    } = this.props;

    if (!params) {
      return;
    }

    // Update the URL with the right doc types and other fields
    if (params.documentsToLoad && params.documentsToLoad.length > 0) {

      let documents = [];
      let loadCount = params.documentsToLoad.length;

      // Get all the document details
      params.documentsToLoad.forEach( async(docToLoad) => {

        const docs = await this.callApi(docToLoad);

        if (docs){
          documents = documents.concat(docs);
        }

        // Remove any docs with no published versions
        const documentsToKeep = documents.map( document => {

          const publishedVersions = document.versions.filter( version => version.status === PUBLISHED);

          // If the document's region is in the ignore list,
          // skip the publishedVersion assignment entirely
          if (
            params.regionsToIgnore &&
            params.regionsToIgnore.includes(document.wtRegion)
          ) {
            return document; // no publishedVersion set
          }

          if (publishedVersions.length > 0){
            // Save the most recent version (will be ordered by date desc)
            document.publishedVersion = publishedVersions[0];
          }

          return document; 

        }).filter( document => document.publishedVersion);

        let initialDocType;
        if(documentsToKeep && documentsToKeep.length > 0 && docToLoad.documentType === params.documentsToLoad[0].documentType){
          
          // If the current doc type matches the first one, then switch it to that, 
          // otherwise show the current one
          initialDocType = docToLoad.documentType;
          
        } else {
          initialDocType = null;
        }

        loadCount = loadCount - 1;

        this.setState({
          documents: documentsToKeep,
          initialDocumentType: initialDocType,
          loading: loadCount === 0 ? false : true,
        });

      });
    }
  }

}

export default (App);
